import * as yup from 'yup';
import React from 'react'; // Import React to use JSX in customDayContent
import { useFormik } from 'formik'; // Import formik to use formik functions

// Validation Schema
export const validationSchema = yup.object().shape({
  // Basic validation for task fields
  name: yup.string().required('Task name is required'),
  description: yup.string().required('Task description is required'),
  task_window_start: yup.string().required('Start time is required'),
  task_window_end: yup
    .string()
    .required('End time is required')
    .test('is-greater', 'End time must be later than start time', function (value) {
      const { task_window_start } = this.parent;
      return task_window_start && value > task_window_start;
    }),
  task_start_date: yup.date().required('Start date is required').nullable(),

  auto_archive_threshold: yup.number().required('Archive Threshold Needed').min(-1),

  task_end_date: yup
  .date()
  .required('End date is required')
  .nullable()
  .min(yup.ref('task_start_date'), 'End date cannot be earlier than start date')


  // Validation for recurring_type 'month'
  .test(
    'specific-day-in-range',
    'Selected date range does not include the specified day',
    function () {
      const { selected, recurring_type, task_start_date, task_end_date } = this.parent;

      if (recurring_type === 'month' && selected && selected.length > 0) {
        const specificDay = selected[0];
        const startDate = this.resolve(yup.ref('task_start_date'));
        const endDate = this.resolve(yup.ref('task_end_date'));
        const isSpecificDayInRange = (day, start, end) => {
          let currentDate = new Date(start);
          while (currentDate <= end) {
            if (currentDate.getDate() === day) return true;
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return false;
        };
        return isSpecificDayInRange(specificDay, startDate, endDate);
      }
      return true;
    }
  )

  // Validation for recurring_type 'week'
  .test(
    'week-day-in-range',
    'Selected date range does not include any of the specified week days',
    function () {
      const { task_start_date, task_end_date, selected, recurring_type } = this.parent;

      if (recurring_type === 'week' && selected && selected.length > 0) {
        const startDate = new Date(task_start_date);
        const endDate = new Date(task_end_date);

        const isDayOfWeekInRange = (selectedDays, start, end) => {
          let currentDate = new Date(start);
          while (currentDate <= end) {
            if (selectedDays.includes(currentDate.getDay())) return true; // Check if any day matches
            currentDate.setDate(currentDate.getDate() + 1);
          }
          return false;
        };
        return isDayOfWeekInRange(selected, startDate, endDate);
      }
      return true;
    }
  )

  // Validation for recurring_type 'monthday'
  .test(
    'monthday-in-range',
    'Selected date range does not include any of the specified days for the monthday',
    function () {
      const { task_start_date, task_end_date, selected, recurring_type } = this.parent;
      if (recurring_type === 'monthday' && selected && selected.length > 0) {
        const startDateObj = new Date(task_start_date);
        const endDateObj = new Date(task_end_date);

        const daysInRange = [];
        for (let d = new Date(startDateObj); d <= endDateObj; d.setDate(d.getDate() + 1)) {
          daysInRange.push(d.getDay());
        }
        const hasValidDay = selected.some((day) => daysInRange.includes(day));
        return hasValidDay;
      }
      return true;
    }
  ),

  selected: yup.array().of(yup.number()).default([]),

  officer_ids: yup
    .array()
    .of(yup.string().required('Each officer ID must be a string'))
    .test(
      'is-valid-officer',
      'At least one officer must be selected when task type is "officer"',
      function (value) {
        if (this.parent.task_type === 1) {
          return value && value.length > 0;
        }
        return true;
      }
    ),

  shift_id: yup
    .string()
    .nullable()
    .test('is-valid-shift', 'Shift must be selected when task type is "shift"', function (value) {
      if (this.parent.task_type === 0) {
        return !!value;
      }
      return true;
    }),

    inmate_ids: yup
    .array()
    .of(yup.string().required('Each inmate ID must be a string'))
    .test(
      'is-valid-inmate',
      'At least one inmate must be selected when "Assign to Specific Inmate(s)" is checked',
      function (value) {
        if (this.parent.assignToInmate) {
          return value && value.length > 0;
        }
        return true;
      }
    ),

});

// Format date for compatibility with Chronic parser in Rails
// export const formatForChronic = (date) =>
//   `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

// Handle date range change
export const handleDateRangeChange = (item, setDateRange, handleFieldChange, formik, onChange) => {
  const startDate = item.selection.startDate.toISOString();
  const endDate = item.selection.endDate.toISOString();

  setDateRange([item.selection]);
  handleFieldChange(formik, 'task_start_date', startDate, onChange);
  handleFieldChange(formik, 'task_end_date', endDate, onChange);

  formik.setFieldTouched('task_start_date', true, false);
  formik.setFieldTouched('task_end_date', true, false);
  formik.validateField('task_start_date');
  formik.validateField('task_end_date');
};

// Handle field change
export const handleFieldChange = (formik, field, value, onChange) => {
  formik.setFieldValue(field, value);
  if (field === 'task_type') {
    formik.setFieldTouched('officer_ids', true, false);
    formik.setFieldTouched('shift_id', true, false);
  }
  if (field === 'assignToInmate') {
    formik.setFieldTouched('inmate_ids', true, false); // Mark 'inmate_ids' as touched when 'assignToInmate' changes
  }
  onChange(field, value);
};

// Custom content for each day
export const customDayContent = (day, intersectDates = [], dateRange) => {

  // Convert the current day to 'YYYY-MM-DD' format for comparison
  const dayString = day.toISOString().split('T')[0];

  // Check if the current day is within intersectDates
  const isIntersectDate = intersectDates.includes(dayString);

  // Convert startDate and endDate from dateRange to 'YYYY-MM-DD' format
  const startDate = dateRange[0].startDate.toISOString().split('T')[0];
  const endDate = dateRange[0].endDate.toISOString().split('T')[0];

  // Check if the day is within the date range
  const isActive = startDate <= dayString && dayString <= endDate;

  // Return the day content with the 'highlighted-day' class if it is an intersectDate
  // and 'active-day' class if it is within the date range
  return (
    <div
      className={`rdrDayNumber ${isIntersectDate ? 'highlighted-day' : ''} ${
        isActive ? 'active-day' : ''
      }`}
    >
      {day.getDate()}
    </div>
  );
};

export const fetchIntersectDates = (values, setIntersectDates) => {

  const token = document.querySelector('meta[name="csrf-token"]').content;

  fetch('/task_generators/preview', {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'X-CSRF-Token': token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      const datesArray = Array.isArray(data.days) ? data.days : [];
      setIntersectDates(datesArray);
    })
    .catch((error) => {
      console.error('Error fetching intersect dates:', error);
      setIntersectDates([]);
    });
};
