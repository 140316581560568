import React, { useState } from 'react';
import { Tabs } from 'antd';
import {
  handleTabChange,
  renderWeeklyTab,
  renderMonthlyTab,
  renderCustomTab,
} from './utils/taskFrequencyTabsUtils'; // Import utility functions

const TaskFrequencyTabs = ({ taskData, onChange }) => {
  const [customFrequencyType, setCustomFrequencyType] = useState('week');
  const [monthlyFrequencyOption, setMonthlyFrequencyOption] = useState('specificDay');
  const [activeTab, setActiveTab] = useState('daily');

  const tabItems = [
    {
      key: 'daily',
      label: 'Daily/One-Time',
      children: <p className='text-gray-600'>Daily notifications will be sent for this task.</p>,
    },
    {
      key: 'week',
      label: 'Weekly',
      children: renderWeeklyTab(taskData, onChange), // Use utility function to render Weekly tab
    },
    {
      key: 'month',
      label: 'Monthly',
      children: renderMonthlyTab(
        taskData,
        onChange,
        monthlyFrequencyOption,
        setMonthlyFrequencyOption
      ), // Use utility function to render Monthly tab
    },
    {
      key: 'custom',
      label: 'Custom',
      children: renderCustomTab(
        taskData,
        onChange,
        customFrequencyType,
        setCustomFrequencyType,
        monthlyFrequencyOption,
        setMonthlyFrequencyOption
      ), // Use utility function to render Custom tab
    },
  ];

  return <Tabs activeKey={activeTab} onChange={(value) => handleTabChange(value, onChange, setActiveTab, setMonthlyFrequencyOption)} items={tabItems} />;
};

export default TaskFrequencyTabs;
